import {
    Autocomplete,
    Breadcrumbs,
    FormControl,
    Grid,
    InputLabel,
    Link as MuiLink,
    MenuItem,
    Paper,
    Select,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Button,
} from '@mui/material';
import { SecuredLayout } from '../Layout/SecuredLayout';
import { useAuth, useProvideSnackBar } from '../../utils';
import React, { useEffect, useState } from 'react';
import { MatrixMap } from './MatrixMap';
import { Link } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditRoadIcon from '@mui/icons-material/EditRoad';

type MatrixStop = {
    StopID: number;
    City: string;
    Name: string;
};

export type TraceItem = {
    Key: string;
    StartLat: number;
	StartLon: number;
	EndLat: number;
	EndLon: number;
	Bearing: number;
	NodeID: number;
	WayID: number;
	Speed: number;
	SpeedLimit: number;
	Surface:    string;
	Length: number;
	RoadClass: string; 
};

type Correction = {
    ID: number;
    LineID: number;
    SourceStopID: number;
    TargetStopID: number;
    TravelTime: number;
    TravelTimeSmallBus: number;
    TravelTimeLargeBus: number;
    Distance: number;
    ValidatedAt: string;
    Valid: boolean;
};

const StyledGrid = styled(Grid)`
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

export default function Matrix() {
    const { user } = useAuth();
    const { showResponseError } = useProvideSnackBar();

    const [busType, setBusType] = useState<string>('small');
    const [useHighways, setUseHighways] = useState<boolean>(true);

    const [stops, setStops] = useState<MatrixStop[]>([]);
    const [sourceStopID, setCurrentSourceStopID] = useState<number | null>(null);
    const [targetStopID, setCurrentTargetStopID] = useState<number | null>(null);

    const [traceItems, setTraceItems] = useState<TraceItem[] | null>(null);
    const [currentPath, setCurrentPath] = useState("");
    const [corrections, setCorrections] = useState<Correction[] | null>(null);

    const fetchStops = async () => {
        const response = await fetch('/api/matrix/stops', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user?.accessToken}`,
            },
        });

        if (!response.ok) {
            await showResponseError(response);
            return;
        }

        const body = await response.json();
        setStops(body.Stops);
    };

    const fetchMatrixRoute = async () => {
        if (!sourceStopID || !targetStopID) {
            return;
        }

        const params = new URLSearchParams({
            SourceStopID: sourceStopID.toString(),
            TargetStopID: targetStopID.toString(),
            BusType: busType,
            UseHighways: useHighways.toString(),
        });

        const response = await fetch(`/api/matrix/route?${params.toString()}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user?.accessToken}`,
            },
        });

        if (!response.ok) {
            await showResponseError(response);
            return;
        }

        const body = await response.json();
        setTraceItems(body.Trace);
        setCurrentPath(body.Route);
    };

    const fetchCorrections = async () => {
        const response = await fetch(`/api/matrix-corrections?SourceStopID=${sourceStopID}&TargetStopID=${targetStopID}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user?.accessToken}`,
            },
        });

        if (!response.ok) {
            await showResponseError(response);
            return;
        }

        const body = await response.json();
        setCorrections(body.Corrections);
    };

    const fetchUpdateCorrection = async (id: Number, valid: boolean) => {
        const response = await fetch(`/api/matrix-corrections`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user?.accessToken}`,
            },
            body: JSON.stringify({
                CorrectionID: id,
                Valid: valid,
            }),
        });

        if (!response.ok) {
            await showResponseError(response);
            return;
        }

        await response.json();
        fetchCorrections();
    };

    useEffect(() => {
        fetchStops();
    }, []);

    useEffect(() => {
        if (sourceStopID === null || targetStopID === null) {
            return;
        }

        fetchMatrixRoute();
        fetchCorrections();
    }, [sourceStopID, targetStopID, busType, useHighways]);

    return (
        <SecuredLayout>
            <Grid container px={2}>
                <Grid container py={1} item xs={12} textAlign={'right'} sx={{ justifyContent: 'space-between' }}>
                    <Breadcrumbs aria-label='breadcrumb' sx={{ display: 'inline-block' }}>
                        <MuiLink
                            component={Link}
                            underline='hover'
                            sx={{ display: 'flex', alignItems: 'center', textAlign: 'right' }}
                            color='inherit'
                            to='/planner'>
                            Tervező
                            <EditRoadIcon sx={{ ml: 0.5, mb: -0.125 }} fontSize={'small'} />
                        </MuiLink>
                    </Breadcrumbs>
                    <Breadcrumbs aria-label='breadcrumb' sx={{ display: 'inline-block' }}>
                        <MuiLink
                            component={Link}
                            underline='hover'
                            sx={{ display: 'flex', alignItems: 'center', textAlign: 'right' }}
                            color='inherit'
                            to='/schedule'>
                            Menetrend
                            <VisibilityIcon sx={{ ml: 0.5, mb: -0.125 }} fontSize={'small'} />
                        </MuiLink>
                    </Breadcrumbs>
                </Grid>
                <StyledGrid container item py={2} spacing={1} sx={{ alignItems: 'start' }}>
                    <Grid item xs={4} sm={4} lg={3}>
                        <Autocomplete
                            disablePortal
                            options={stops.map(stop => {
                                return { label: `${stop.City}, ${stop.Name} (${stop.StopID})`, id: stop.StopID };
                            })}
                            fullWidth
                            renderInput={params => <TextField {...params} size={'small'} variant='standard' label='Indulási megálló' />}
                            onChange={(event: any, newValue: any) => {
                                setCurrentSourceStopID(newValue.id as number);
                            }}
                            isOptionEqualToValue={(a: any, b: any) => a.id === b.id}
                        />
                    </Grid>
                    <Grid item xs={4} sm={4} lg={3}>
                        <Autocomplete
                            disablePortal
                            options={stops.map(stop => {
                                return { label: `${stop.City}, ${stop.Name} (${stop.StopID})`, id: stop.StopID };
                            })}
                            fullWidth
                            renderInput={params => <TextField {...params} size={'small'} variant='standard' label='Cél megálló' />}
                            onChange={(event: any, newValue: any) => {
                                setCurrentTargetStopID(newValue.id as number);
                            }}
                            isOptionEqualToValue={(a: any, b: any) => a.id === b.id}
                        />
                    </Grid>
                    <Grid item xs={4} sm={4} lg={2}>
                        <FormControl fullWidth>
                            <InputLabel variant='standard'>Busz típus</InputLabel>
                            <Select value={busType} label='Busz tipus' onChange={e => setBusType(e.target.value)} variant='standard'>
                                <MenuItem value={'small'}>Kisbusz</MenuItem>
                                <MenuItem value={'large'}>Nagybusz</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4} sm={4} lg={2}>
                        <FormControl fullWidth>
                            <InputLabel variant='standard'>Autópályahasználat</InputLabel>
                            <Select value={useHighways} label='Autópályahasználat' onChange={e => setUseHighways(e.target.value === "true")} variant='standard'>
                                <MenuItem value={"true"}>Igen</MenuItem>
                                <MenuItem value={"false"}>Nem</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </StyledGrid>

                {traceItems !== null && traceItems.length > 0 ? (
                    <Grid container py={2} item spacing={1} sx={{ alignItems: 'start' }}>
                        <Grid item xs={12} lg={6}>
                            {corrections !== null && corrections.length > 0 ? (
                                <TableContainer component={Paper} sx={{ marginBottom: '20px' }}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align={'left'}>Utazási idő</TableCell>
                                                <TableCell align={'left'} width={'100px'}>Ellenőrizve</TableCell>
                                                <TableCell align={'left'} width={'250px'}>Állapot</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {corrections?.map(row => (
                                                <TableRow key={`${row.ID}`}>
                                                    {row.TravelTimeLargeBus > 0 ? (
                                                        <TableCell style={{ padding: '0 20px 0 20px' }}>
                                                            {Math.round(row.TravelTimeSmallBus / 60)} perc (nagybusz)
                                                        </TableCell>
                                                    ) : (
                                                        <TableCell style={{ padding: '0 20px 0 20px' }}>
                                                            {Math.round(row.TravelTimeLargeBus / 60)} perc (kisbusz)
                                                        </TableCell>
                                                    )}
                                                    <TableCell style={{ padding: '5px 20px 5px 20px' }}>{row.ValidatedAt === '' ? 'nem' : 'igen'}</TableCell>
                                                    <TableCell style={{ padding: '5px 20px 5px 20px' }}>
                                                        {row.Valid ? (
                                                            <>
                                                                Elfogadva{' '}
                                                                <Button
                                                                    sx={{ mx: 1, borderRadius: 50 }}
                                                                    variant={'contained'}
                                                                    size={'small'}
                                                                    color={'error'}
                                                                    onClick={() => fetchUpdateCorrection(row.ID, false)}>
                                                                    Törlés
                                                                </Button>
                                                            </>
                                                        ) : (
                                                            <>
                                                                Nincs elfogadva
                                                                <Button
                                                                    sx={{ mx: 1, borderRadius: 50 }}
                                                                    variant={'contained'}
                                                                    size={'small'}
                                                                    color={'info'}
                                                                    onClick={() => fetchUpdateCorrection(row.ID, true)}>
                                                                    Elfogad
                                                                </Button>
                                                            </>
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            ) : (
                                <></>
                            )}

                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>WayID</TableCell>
                                            <TableCell>Sebességkorlát</TableCell>
                                            <TableCell>
                                                Sebesség
                                            </TableCell>
                                            <TableCell>Hossz</TableCell>
                                            <TableCell>Irány</TableCell>
                                            <TableCell>Burkolat</TableCell>
                                            <TableCell>Típus</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {traceItems?.map(row => (
                                            <TableRow key={`${row.Key}`}>
                                                <TableCell>
                                                    <a rel={'noreferrer'} href={`https://www.openstreetmap.org/way/${row.WayID}`} target='_blank'>
                                                        {row.WayID}
                                                    </a>
                                                </TableCell>
                                                <TableCell>
                                                    {row.SpeedLimit}
                                                </TableCell>
                                                <TableCell>{row.Speed}</TableCell>
                                                <TableCell>{row.Length}</TableCell>
                                                <TableCell>{row.Bearing}</TableCell>
                                                <TableCell>{row.Surface}</TableCell>
                                                <TableCell>{row.RoadClass}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item xs={12} lg={6} style={{ width: '100%', height: '500px' }}>
                            {traceItems.length > 0 && <MatrixMap items={traceItems} path={currentPath}/>}
                        </Grid>
                    </Grid>
                ) : (
                    <></>
                )}
            </Grid>
        </SecuredLayout>
    );
}
